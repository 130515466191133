/******************************************************************
Site Name: 
Author: 

Stylesheet: Retina Screens & Devices Stylesheet

When handling retina screens you need to make adjustments, especially
if you're not using font icons. Here you can add them in one neat
place.

******************************************************************/
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo-2x.png'); }
.webp #logo { background-image: url('../images/logo-big.webp'); }*/


