﻿/******************************************************************
Site Name:
Author:

Stylesheet: Print Stylesheet

This is the print stylesheet. There's probably not a lot
of reasons to edit this stylesheet. If you want to
though, go for it.

******************************************************************/

/*
UNIVERSAL STYLING
Apply the universal selector (*) to remove background colors and shadowing and reset the default font color to black. 
Saves ink and expedites the printing process, it is important to consider how logos and branding elements will appear in grayscale.
*/

*{
	background: transparent !important;
    color: lighten($black, 20%) !important;
    text-shadow: none !important;
    filter:none !important;
    -ms-filter: none !important;
	
	/*
	If you wish to maintain background colors or images, you can effectively “force” the browser to do so.
	Handy when you want to turn a web page into a PDF file.
	*/
	
	-webkit-print-color-adjust: exact; 
	print-color-adjust: exact;
}

/*
TYPOGRAPHY
*/
	body {
		font-size: 12pt;
	}
	
	p, h2, h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
	
	a, a:visited {
		color: $black !important;
		text-decoration: underline;

		// show links on printed pages
		//&:after {
		  //content: " (" attr(href) ")";
		//}

		// show title too
		//abbr[title]:after {
		  //content: " (" attr(title) ")";
		//}
	}

	// if links are shown, clear out an inline JS, or anchor tags
	a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

/*
GENERAL CONTENT STYLING
*/

	// prevent content areas from breaking onto new page
	h2, h3, tr, img, pre, blockquote, ul {
		page-break-inside: avoid;
	}
	
	pre, blockquote {
		border: 1px solid lighten($black, 20%);
	}

	thead {
		display: table-header-group;
	}

	//prevent images from bleeding over the edge of the printed page
	img {
		max-width: 100% !important;
	}
	
	// stop rwd - force containing elements to be full width
	body, article, section {
		width: 100% !important;
		margin: 0;
		padding: 0;
	}
	
	#leftcol {
		min-width: 786px!important;
	}
		
	// Ensure that articles always start on a fresh page:
	//article {
	   //page-break-before: always;
	//}
	
	

    @page {
      margin: 0.5cm;
	  size: A3;
    }

/* ============================== */
/* ! Turn OFF none-printable items  */
/* ============================== */   

    // Hide generic content printed pages don't need (menus, high quality graphics)
    .hide,
	#hero,
	#rightcol,
    nav,
	
	
	// site specific
	.parallax-slider,
	#userblocks,
	.CaseStudy,
    #imageslider,
    #breadcrumbs {
      display: none;
    }


/* ============================== */
/* ! Turn ON printable items  */
/* ============================== */	
	@media print {
		.printable {display: block;}
	}
	
	//Site Specific
	body.Home .header, body.Home .header .parallax-window, body.Home .header .topBlock, body.CoffeeSolutions .header, body.CoffeeSolutions .header .parallax-window, body.CoffeeSolutions .header .topBlock {
		height: 20vh;
	}


  