/******************************************************************
Site Name:
Author:

Stylesheet: Admin Tablet & Small Desktop Stylesheet

Here's where I've put the Admin stuff cause I'm not sure what to do with it just yet.
Keeping it clean and seperate from the new stuff.

******************************************************************/



/* ============================= */
/* ! Admin MENU   */
/* ============================= */

.adminmenucontainer {width: 100%; background-color: #eeeeee; }
.adminmenu {width: 100%;}
.adminmenurow {text-align: center; height: 24px;}
.adminrootheading {line-height: 14px; display: block; color: #000000; padding: 6px; font-weight: bold; font-size: 13px;}
.adminrootitem {text-align: center; background-color: #eeeeee; color: #000000; text-decoration: none; width: 14.28%; text-indent: 30px;}
a.adminrootmenulink {line-height: 14px; display: block; color: #000000; padding: 6px; text-decoration: none; font-weight: bold; font-size: 13px;}
.adminsubmenu {line-height: 14px; width: 180px; display: none; position: absolute; background-color: #eeeeee; margin: 0px; padding: 0px; text-decoration: none; list-style-type: none; z-index: 1000;}
.adminmenuitem {height: 24px; padding: 3px;}
a.adminmenulink {line-height: 14px; display: block; color: #000000; padding: 6px; text-align:left; text-decoration: none; font-size: 12px; text-indent: 10px;}
body.admcaptcha_edit #leftcol {width:100%;}
body.admcaptcha_edit #leftcol .block, body.admcaptcha_edit #rightcol {display:none;}
body.admhome #topblocks { display: none; }


/* ============================= */
/* ! MAIN MENU   */
/* ============================= */

body.admindex .header div.menu, body.admhome .header div.menu, body.admnewsection .header div.menu {position: relative!important;}
body.admindex .header, body.admhome .header, body.admnewsection .header { z-index:999!important;}
body.admindex .header #logo, body.admhome .header #logo, body.admnewsection .header #logo{position: relative; float: left!important;}

body.admindex #hero::before, body.admhome  #hero::before {
    display: none
}


body.admindex,
body.admhome,
body.admnewsection {
    color: #414142;

    .header, .header .parallax-window, .header .topBlock {
        height: auto;
        overflow: visible;
    }

    .cke_tpl_list {
        overflow-y: auto;
        max-height: 400px;
    }

    .cke_tpl_item table.cke_tpl_preview td {
        vertical-align: middle;
        padding: 0.25em;

        &:first-child {
            width: 70%;
        }

        &:last-child {
            width: 30%;
        }
    }

    .topMenu {

        .nav {

            > li {
                padding: 0 0.5em;

                > a {
                    font-size: 1em;
                }
                /*turn some of the main nav items ON for admin*/
                &:first-child,
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    display: inline-block;
                }

                &:first-child {
                    > a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    /* end .topmenu */
}


/*********************
HOME BLOCK LAYOUT
**********************/
/* Needed to lay home blocks out in admin interface and overwrite some of the styling*/
.columnlayout div.admin_content_block, div.admin_content_block > div {width: 96%!important; margin: 0 auto;}
div.admin_content_block div {margin: 0px auto; padding: 0 0 0 0; background: #fff; border: none;}
body.admindex .columnlayout > div { width: 100%;}
body.admhome #imageslider, body.admhome #hero, body.admhome #bottomblocks { display: none;}
#addBlock_T, #block_new_T{position:relative; z-index: 1;}


/* ============================= */
/* ! Admin CKEDITOR  */
/* ============================= */

.cke_button__kook_templates, .cke_button__kook_forms, .cke_button__kook_slideshows, .cke_button__kook_embed, .cke_button__kook_products, .cke_button__kook_templates_label, .cke_button__kook_forms_label, .cke_button__kook_slideshows_label, .cke_button__kook_embed_label, .cke_button__kook_products_label {
    display: inline !important;
}
.cke_button__kook_templates_icon, .cke_button__kook_forms_icon, .cke_button__kook_slideshows_icon, .cke_button__kook_embed_icon, .cke_button__kook_products_icon {
    display: none !important;
}
.cke_editable {
    line-height: normal !important;
}
body.cke_editable {
	background-color:#fff;
    line-height: normal !important;
}

/*Content Template styles*/

.cke_dialog_contents_75 .cke_tpl_item table.cke_tpl_preview td {vertical-align: middle!important; padding:0.25em!important;}
.cke_dialog_contents_75 .cke_tpl_item table.cke_tpl_preview td.cke_tpl_preview_img {width: 150px!important;}

/*********************
Captcha
**********************/
#grdCaptcha td {padding: 0.25em 0.5em; word-wrap: break-word;}



/* ============================= */
/* ! Admin DRAG DROP SITEMAP  */
/* ============================= */
#sitemap {
    width: 70%;

    ul, ol {
        list-style: none;

        li {
            > div {
                /*background-color: $color-quaternary;*/
                border: 1px solid darken($white, 10%);
                padding: 0.5em;
                margin-bottom: 0.5em;
                position: relative;
                cursor: move;
            }

            &.dragging:hover {
                > div {
                    background-color: darken($white, 20%);
                    border: 1px solid darken($white, 25%);
                }
            }

            &.active:hover {
                background-color: $white;
                border: 1px dashed $color-primary;
            }

            ol {padding-left:40px;}
        }
    }
}

#sitemap .placeholder {
    outline: 1px dashed #4183C4;
    /*-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			margin: -1px;*/
}

.notification {
    color: #555;
    border-radius: 10px;
    font-family: Tahoma,Geneva,Arial,sans-serif;
    font-size: 11px;
    padding: 10px 10px 10px 36px;
    margin: 10px;
    background: #e9ffd9 url('/images/success.png') no-repeat 10px 50%;
    border: 1px solid #a6ca8a;
}

#sitemap .dropzone {
    height: 6px;
    background-color: #fff;
    border-bottom: 4px solid #fff;
}

.HideAdminField {
    display: none;
}
/* admin photo gallery page*/
.ui-dialog {
    z-index: 1000 !important;
}
#photo-edit-dialog {
    input {
        width: 100%;
    }

    textarea {
        height: 103px;
        width: 100%;
    }

    .photo-edit-footer {
        margin-top: 20px;
    }

    button {
        background: transparent;

        &:hover, &:focus {
            background: transparent;
        }
    }

}
body.admgallery {
    #gallery {
    .photo-sub-container {
        position: relative;
        width: 100%;
        max-height: 226px;
        overflow:hidden;
    }

    li {
        padding: 2em;
    }

    .photo-img-container {
        border: 1px solid #ccc;
    }

    .photo_path {
        vertical-align: middle;
        height: auto;
    }

    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
        background-color: black;
    }

    .photo-sub-container:hover .overlay {
        opacity: 0.3;
    }

    .icon {
        color: white;
        font-size: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
    }

    button {
        width: 40px;
        padding: 0em 0em;
        border: 0px solid #4e4f51;
    }

    .photo_schedule{
        font-size:smaller;
    }
}

}
