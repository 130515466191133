/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/


/*margins*/
h2, .h2, .gmb-review p{ margin: 0.5em 0;}
#bottomBlocks h4, #bottomBlocks .h4 { margin:  2em 0 1em 0;}

/*sizing*/
#hero h1, #hero.h1 { font-size: 3.2em; }
h1, .h1, h2, .h2 { font-size: 2em; }
#accountMenu > a:first-of-type, .slideHeading1, .slideHeading2 {font-size:1.2em;}
.slideDescription {font-size:1em;}


.topMenu .nav > li > a {
    @include bp(papa-screen) {
        font-size: 120%;
    }
}

/*********************
LAYOUT & GRID STYLES
*********************/

.row, 
body.Home #content #leftcol > div > div, 
body.fullWidth #leftcol > div > div{
    padding: 3em 0;
}
	
/*********************
HEADER STYLES
*********************/

.header {
    position: relative;
    z-index: map-get($zindex, header);

    .row {
        gap:2rem;
    }
}


/**************************
HERO - Home
***************************/
/*body.Home #hero {max-height:75vh;}*/
body:not(.Home) #hero {
   /*height:300px;*/
    overflow:hidden;
     .block > p img {
            min-width:100%;
            max-width:initial;
            height:auto;
       }
}




/*********************
NAVIGATION STYLES
*********************/

.topMenu {		
	.nav {		
		> li {
            margin: 0 1.25em;
		}
	}
}




/*********************
CONTENT STYLES
*********************/
#content #leftcol > div > .first {padding:0 2em;}

.col-2, .col-3, .col-4, .col-6 {
    grid-gap: 2.25em;
}

/* specific styling to hold background illustrations */

body.Home #content #leftcol > div:first-of-type,
body.Home #content #leftcol > div#div433,
body.Home #content #leftcol > div#div431,
body.Home #content #leftcol > div#div434,
body.fullWidth #content #leftcol > div:first-of-type,
body.fullWidth #content #leftcol > div:last-of-type{

    &:after {
        width:20%;
    }

}

/*********************
BLOCK LAYOUT
**********************/	


 #bottomBlocks {
     .block {margin-bottom:0em;}

 }

 /* LEFT BLOCKS - Mission statement*/
    #homeMission{
        .row {
            height: 60vh;
            display: flex;
            align-items: center;
        }
    }


/*Full width slider */


ul.responsive {
	
	.slick-list {
		.slick-track {
			.slick-slide {

                .slideshow_caption {
                    /*text-align: center;*/
				    width: 50%;
                }

			}
		}
	}
}



// BOOK FORM Slide-out
#bookForm {
    #bookForm-inner {
        width: 25vw;
    }
}