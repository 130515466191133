/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 98%;
	line-height: 1.4;
	color: $black;
    font-weight:300;
} 

.mobileOnly {display:inline-block!important;}
.desktopOnly {display:none!important;}

/*set to avoid horizontal scroll on off-screen lottie animation*/
main {overflow:hidden;}

/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
Z_INDEX
*********************/
.cd-top { z-index: 9999;}
.header { z-index: 901;}
#bookForm { z-index: 900;}

ul.children {z-index: 800;}


/*********************
LAYOUT & GRID STYLES
*********************/

.row, 
body.Home #content #leftcol > div > div, 
body.home #content #leftcol > div > div, 
.row-small, body.fullWidth #leftcol > div > div {
    margin: 0 auto;
    width: 93%;
    padding: 3em 0;
}

/* Turn off padding on some rows*/
.header .row,
body.Home #content #leftcol > div#div405 > div,
body.apartment-template #leftcol > div:nth-child(2) > div
{max-width: none; width: 100%; padding: 0em 0;}


/*********************
LINK STYLES
*********************/

a {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

h1, .h1, h2, .h2, h3, .h3, .siteName {
	font-family:$headings;
	a {
		text-decoration: none;
	}
}
/*weights*/

h1, .h1, h4, .h4, .button, button, {font-weight:400;}
h2, .h2, h3, .h3, label, .topMenu .nav > li, .link, .link-2, #accountMenu > a:first-of-type {font-weight:300;}
strong, h3, .h3, .legend > span, .slideHeading1 {font-weight:600;}


/*margins*/
h1, .h1, h2, .h2, h3, .h3, .gmb-review p, p.slideHeading1, p.slideHeading2 { margin: 0.25em 0;}
h3, .h3, h4, .h4, .gmb-review p { margin: 0.75em 0;}

p + h3, p + .h3 {margin: 1em 0 0.5em 0;}

/*line-height*/
#hero h1, #hero.h1  { line-height: 1.3;}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, label, [data-control]  { line-height: 1.2;}

/*letter spacing*/
.xsmtext, .menu-btn span, .step, #pushMenu ul > li > a, .button, button, #footer nav, .slideHeading2 { letter-spacing:1px; }

/*sizing*/
.grid-3 .card-image i { font-size: 3.5em; }
#hero h1, #hero.h1 { font-size: 2em; }
h1, .h1, h2, .h2 { font-size: 1.6em; }
h3, .h3, .legend > span {font-size:1.4em;}
.bigger,  h4, .h4,[data-control] {font-size:1.2em;}
.smtext, #breadcrumbs, thead, .respond-form label, .holdForm ul, #pushMenu .nav-small, #pushMenu ul li ul li a, .news-item-label, #message, .slideDescription { font-size: 0.85em; }
.xsmtext, .menu-btn span { font-size: 0.65em; }

.responsive .h3:after {display:none; margin-bottom:0; }


/*styling*/ 
h4, .h4, .step, #pushMenu > ul > li > a, .button, button, .slideHeading2 { text-transform: uppercase;}




/*************************
Colours
*************************/	


.blue, .pushy a, #pushMenu ul li a:hover, .topMenu .nav > li a:hover,
.darkHeader #accountMenu, #accountMenu .h2, .darkHeader .phone, body.home #content ul li::before, blockquote:before, 
.facilities li > i, .legend > i,
.respond-form h2, .fa-li, .slideshow_caption a.button
{color: $color-primary;}

.sand, #footer a:hover {color: $color-secondary;}

.black, .topMenu .nav > li a, #pushMenu ul li a, .responsive .h3, h3 > a, .menu-btn
{color: $black; }

.grey, 
{color: lighten($black, 40%); }

.white, 
ul.responsive3 .slideshow_caption a,
.slick-slider button:before,
#hero h1, #hero .h1,
.holdForm, .holdForm h2, .holdForm a.button,
#footer #facebook a,  #copyright, #footer, #footer a, .cd-top i, 
#hero .slick-slider button:before, #hero .heading h1, #hero .heading .h1,
#homeMission,
#bottomBlocks
{
    color: $white;
}

.orange {color: $alert-yellow;}




/*************************
Turn off printable items
*************************/	
	.printable {display: none;}
	

/*********************
SPRITE
*********************/
/*use modernizer script stop detect browser fallback for next gen images*/
/*.no-webp .box { color: red; }
.webp .box { color: green; }*/	
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo.png'); }
.webp #logo { background-image: url('../images/logo.webp'); }*/

    #logo {
		display: block;
		margin: 0 auto;
        background: transparent url('../images/logo.svg') no-repeat center top;
        background-position: center top;
        width: 125px; height: 60px;
        background-size: 125px 60px;
	}



/*********************
HEADER STYLES
*********************/

/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */


.header {	

	.clearHeader {
		position: relative;
		background-color: $white;
		@include transition(all 0.2s ease-in-out);
	}
	 
	.darkHeader {
		position: fixed;
		background-color: $white;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		@include transition(all 0.2s ease-in-out);
        top: 0;
	}
	
}

/* position header elements as needed */


.header {
    position: relative;
    z-index: map-get($zindex, header);

    .row {
        /*use flex for better centering*/
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        gap:1rem;

        min-height:112px;
        height:12vh;

    }

    .menu {
        width: 100%;
    }

    #mainMenu, #accountMenu {
        align-items: center;
        flex-basis: auto;
        flex-grow: 1;
    }

    #logo {
        display: inline-block;
        flex-shrink:0;
        min-width:125px;
    }

    #accountMenu {
        text-align: right;
        margin-right: 0.5em;

        > a, button  {
            display: inline-block; vertical-align:middle;
        }
        > a  {
            padding: 0.5em 0; margin: 0 0.25em;
        }
    }

    .menu-btn {
        display: block;
        text-align: center;
        span {display:block;}
    }
}




/***********************
HERO - HERO
************************/

#hero {
	position: relative;
    overflow: hidden;
    z-index: map-get($zindex, sendBackwards);

    > p, picture {margin:0 0; display:block; height:100%;}

    img {
        @include center(true, true); 
        width:100%;
        height:100%;
        min-width:100%;
        object-fit:cover;
        z-index: map-get($zindex, sendToBack);
    }
    
    &:before {
        content:""; 
        position:absolute; 
        display: block; 
        width:100%; 
        height:100%; 
        background-color: rgba(0, 0, 0, 0.35); 
        z-index: map-get($zindex, sendBackwards);
    }
}

body.Home #hero {
    min-height:560px;
    height: 65vh;
    .caption { 
        @include center(true, true); width: 85%;
        z-index: map-get($zindex, bringBackwards);
    }
}



	
/*********************
NAVIGATION STYLES
*********************/
#touchMenu,
.menu-btn, .menu-btn span {display:block;}
.menu-btn span {text-align:center;}

#mainMenu {
    display:none;
}

/*hide home link in 2nd nav*/
/*#pushMenu {
    ul:nth-of-type(2){
        > li:first-of-type{display:none;} 
    }
}*/

.topMenu, #pushMenu {
	/*list-style:none;
	float: right;
	clear: right;*/
		
		
		a.toggleMenu {
			width: 100%; 
			padding: 2% 4%;
			text-transform: uppercase;
			margin-bottom: 0;
				span {
					display: block; 
					width:auto; 
					float:right;
					padding-left: 0.5em;
				}
					
		}
		
		.nav, .nav-small {
			/*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/

                > li {margin: 0.25em 0;}
		        
				li {
					position: relative;
					text-align: left;
                    
					a {
						display: block;
						text-decoration: none;
						padding: 0.5em 0em;
						background: darken($color-quintus, 0%);
						border-bottom: 1px solid rgba(130, 154, 193, 0.21);
				
						&:hover, &:focus {
							background: darken($color-quintus, 5%);
						}
						&.parent:before {
							content: "\f107";
							font-family: "Font Awesome 6 Pro";
                            font-weight: 300;
							display: block;
							float: right;
							font-size: large;
							padding-right: 0.5em;
							line-height: 1.2;
						}
					}
					&.hover {
						> a.parent:before {
							content: "\f106";									
						}
					}
			
					&:first-child {}
			
					&:last-child {}
			
					/* Sub-menu level 2 */
					ul,
					ul.sub-menu,
					ul.children {
						margin-top: 0;
						border-top: 0;
						display: none;
			
						li {
			
							a {
								padding-left: 1em;
								background: darken($color-quintus, 0%);
								border-bottom: 1px solid rgba(130, 154, 193, 0.21);
											
								&:hover,
								&:focus {
									background: darken($color-quintus, 5%);
								}			
								&:link {}
								
								
							}
			
							&:first-child {}
			
							&:last-child {}
							
							/* Sub-sub-menu level 3	*/
							ul {
													
								li {
					
									a {
										padding-left: 1.5em;												
										background: darken($color-quintus, 5%);
										&:hover,
										&:focus {
											background: darken($color-quintus, 10%);
										}
									}
									
									/* Sub-sub-menu level 4	*/
									ul {
															
										li {
							
											a {
												padding-left: 2em;
												background: darken($color-quintus, 10%);
												
											}
							
										}
							
									}/* end level 4	*/
					
								}
					
							}/* end level 3	*/
			
						}
			
					}/* end level 2	*/
					
					/* Hover effects */
					&:hover ul {
						
					}
					
			
				} /* end .menu li */
			
			
		
		} /* end .nav */

        .nav > li.hover > ul { left: 0; display: block;}
        .nav li li.hover > ul { left: 100%; top: 0; display: block; }

}/* end .topMenu */



/*********************
CONTENT STYLES
*********************/
/* content area all inner pages set different or alternate row colours here*/
body.Home #content #leftcol > div:first-of-type,
body.Home #content #leftcol > div#div434,
body.fullWidth #content #leftcol > div:nth-child(odd),
body.fullWidth #content #leftcol > div:first-of-type,
body.apartment-template #content #leftcol > div:nth-child(4),
.pushy
 { background: lighten($color-secondary,20%); }

/* content area all inner pages */
#content, #homeBlocks, #bottomBlocks  { position:relative; background:$white;}

/* specific styling to hold lottie animations */
.wrap-lottie { 
    position:relative;
    div.hold-lottie {
        position: absolute; right:-8%; bottom:8%;
    }
}

/* specific styling to hold background illustrations */

body.Home #content #leftcol > div:first-of-type,
body.Home #content #leftcol > div#div433,
body.Home #content #leftcol > div#div431,
body.Home #content #leftcol > div#div434,
body.fullWidth #content #leftcol > div:first-of-type,
body.apartment-template #content #leftcol > div:nth-child(5),
body.fullWidth #content #leftcol > div:last-of-type/*,
body.gallery #content #leftcol > div:first-of-type,
body.contact #content #leftcol > div:first-of-type,
body.location  #content #leftcol > div:first-of-type*/{
    position:relative;
    z-index: map-get($zindex, sendBackwards);
    > div { position:relative; z-index: map-get($zindex, bringToFront);}
    &:after {
        display: block;
        width:40%;
        position: absolute;
        background-size: contain;
		z-index: map-get($zindex, bringForwards);
    }

}
/*Palm*/
body.Home #content #leftcol > div:first-of-type,
body.apartments #content #leftcol > div:first-of-type,
body.facilities #content #leftcol > div:first-of-type,{
    &:after {
		right:5%;
        top:-5%;
        background: transparent url('../images/bg-palm-top.svg') no-repeat center top;
        background-position: center top;
        /*width: 320px; height: 292px;
        background-size: 320px 292px;*/
        aspect-ratio:320/292;
    }

}
/*unbrella*/
/*body.Home #content #leftcol > div#div431 {
    &:after {
		left:-10%;
        bottom:-15%;
        background: transparent url('../images/bg-umbrella-bottom.svg') no-repeat center top;
        background-position: center top;
        width: 427px; height: 546px;
        background-size: 427px 546px;
    }

}*/
/*flower*/
body.Home #content #leftcol > div#div434,
body.gallery #content #leftcol > div:first-of-type,
body.contact #content #leftcol > div:first-of-type{
    &:after {
		right:0%;
        top:-5%;
        background: transparent url('../images/bg-flowers-top.svg') no-repeat center top;
        background-position: center top;
        aspect-ratio:320/292;
    }

}


/*Ball bottom*/
body.Home #content #leftcol > div#div433,
body.apartments #content #leftcol > div:nth-child(5),
body.fullWidth #content #leftcol > div:last-of-type{
    &:after {
		left:-15%;
        bottom:-5%;
        background: transparent url('../images/bg-ball-bottom.svg') no-repeat center top;
        background-position: center top;
        aspect-ratio:346/546;
    }

}

/*Ball top*/
body.location  #content #leftcol > div:first-of-type,
body.specials #content #leftcol > div:first-of-type{
    &:after {
		right:0%;
        top:-5%;
        background: transparent url('../images/bg-ball-top.svg') no-repeat center top;
        background-position: center top;
        aspect-ratio:299/310;
    }

}


/*Usage - format content in columns using grid */

.col-3, .col-4, .col-6, {

    list-style: none;
    display: grid;
    grid-gap: 1.25em;
    margin-bottom: 1em;

    > div, > li {
        margin-bottom: 0em;
    }

}



.col-3 {
    grid-template-columns: repeat(3, 1fr);

    > div, > li {
        grid-column: span 3;
    }

}

.col-4 {
    grid-template-columns: repeat(4, 1fr);

    > div, > li {
        grid-column: span 4;
    }

}

.col-6 {
    grid-template-columns: repeat(6, 1fr);

    > div, > li {
        grid-column: span 2;
    }

}

/*Usage - apartment legend on accommodation overview page*/
.legend {
    display: flex;
    align-items: flex-start; // Align children to the start of the container
    gap: 0.5rem; // Add a 0.5rem gap between child elements

    > * {
        flex: 0 0 auto; // Allow all child elements to be auto-sized based on content
        align-self: center; // Center the child elements vertically
    }
    > i {padding-right:0.5rem; }
}


/*Usage - add offset keyline to images*/
.img-wrap, .apartmentBlocks > div > p:first-of-type {
    position:relative;
    z-index: 0;
    margin-right:1.5em;
    margin-top:3em;

    picture {
        aspect-ratio: 450/600;
        position: relative;        
    }
    img, picture {
        z-index:1;
        width: 100%!important;
        height: 100%!important;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%;
        opacity: 1;
        display: block;

        -webkit-border-top-left-radius: 250px;
        -webkit-border-top-right-radius: 250px;
        -moz-border-radius-topleft: 250px;
        -moz-border-radius-topright: 250px;
        border-top-left-radius: 250px;
        border-top-right-radius: 250px;
    }
    
    &:before {
        content: "";
        position: absolute;
        background: transparent;
        border: 2px solid $color-secondary;
        height: 100%;
        width: 100%;
        z-index: -1;
        top: -1.5em;
        left:auto;
        right: -1.5em;
        bottom: auto;

        -webkit-border-top-left-radius: 250px;
        -webkit-border-top-right-radius: 250px;
        -moz-border-radius-topleft: 250px;
        -moz-border-radius-topright: 250px;
        border-top-left-radius: 250px;
        border-top-right-radius: 250px;
       
    }
    
}

// usage - specials

.article-stack {
  display: grid;
  position: relative; // imperative for the overlapping to work
}

.article-stack {
    
    .article-stack__item--bottom {
        img { @include border-radius($small-border-radius);}
    }

    .article-stack__item--top {
        background: lighten($color-secondary,15%);
        padding:8%;
        @include border-radius($small-border-radius);
        box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
        -webkit-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */ animation-delay: 0.5s;
    }
}

div.first{
    .img-wrap {
        margin-right:unset;
        margin-left:1.5em;
        &:before {
            right: auto;
            left: -1.5em;
        }
    }
}
div.last{
    .img-wrap  {
        margin-right:1.5em;
        img {@include border-radius($small-border-radius);}
        &:before {
            @include border-radius($small-border-radius);
        }
        
    }
}

.apartmentBlocks > div > p:first-of-type {
    margin-right:1.5em;
    img {
        cursor:pointer; @include border-radius($small-border-radius);
        &:hover {box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); @include transition(all 0.2s ease-in-out);}
    }
    &:before {
        @include border-radius($small-border-radius);
    }
    picture {
        aspect-ratio: 500/333;
        position: relative;        
    }
}

.fa-ul { padding-left: 60px; }
	
			p {}


			ul, ol, table, dl {}

			#content ul:not(.slick-slider):not(.slick-dots):not(#gallery) {
                list-style: none;
                padding: 0 0 0 0;
                margin: 1.5em 0em;

                li:not(.slick-slide) {
                    position: relative;
                    padding-left: 2em;
                    margin-bottom: 1.25em;
                    line-height:1.6;

                        &:before {
                            /*content: "\f578";
                            font-family: "Font Awesome 6 Pro";
                            font-weight: 300;*/
                            content:"";
                            position: absolute;
                            display: block;
                            background: transparent url('../images/starfish.svg') no-repeat center top;
                            background-position: center top;
                            width: 24px; height: 24px;
                            background-size: 24px 24px;
                            top: 0;
                            left: 0;
                            line-height: 1;
                        }

                        ul {
                            li{
                                padding-left: 2em;
                                margin-bottom: 0.5em;
                                line-height:1.4;
                                &:before {
                                    content: "\f00c";
                                    font-family: "Font Awesome 6 Pro";
                                    font-weight: 300;
                                    background: none;
                                    line-height: 1.2;
                                }
                            }
                        }
                }



            }

          

			ol {
                padding-left:0;
				li {}

			}

			blockquote {
				margin: 0 0 0 0;
				text-align: left;
								
			}
			
			blockquote {
				padding: 1em;
				p {
					padding-left: 2em;
					margin-top: 0;
				}
				h5 {
					margin: 0.4em 0;
				}
								
				&:before {
					content: "\f10d";
					font-family: "Font Awesome 6 Pro";
                    font-weight: 300;
					display: block;
					float:left;
					padding-right: 0.5em;
					line-height: 1.2;
									
				}
				
				a {
					text-decoration: none;
				}
				
				em{
					text-align: right;
					}

			}

			dl {}

			dt {}

			dd {
				margin-left: 0;
				font-size: 0.9em;
				color: #787878;
				margin-bottom: 1.5em;
			}

			img {
				/*margin: 0 0 1.5em 0;*/
				max-width: 100%;
				height: auto;
			}


	#breadcrumbs { 
		/*background-color: lighten( $color-secondary, 20%);*/
				
		> div {
            /*text-align: center;*/
             div {
			    display: inline;
            }
		}
	}



/*********************************
 FLEX BLOCKS
*********************************/

.block {}

    .columnlayout {
        position:relative;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1.5em;

        /*turn off basic li styling*/
        padding: 0 0 0em 0;
        li:before {display:none;}
        li {margin-bottom: 0em; padding-left:0em;}

        > div, li, article {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            
            > p:first-of-type {margin:0 auto;}
            > div {
                width: auto;
            }
        }

        
        /*feature - text left, image right x2*/
        &.feature-text-left{
            display: block;
            > div {}
        }

        article.newsBlock {width:100%;}
        &.news {
            /*hide admin code from front-end*/
            > div[align="center"], > a {display:none;}
        }
    }

   /* #gallery */
    .grid-gallery {
        li {flex-grow:0;}
    }

   /*Facilities*/
   ol.facilities {
      li {
          text-align: center;
           > i {display:block; padding:1.25rem;}
       }
   }

  
    /* RIGHT BLOCKS */
    #rightcol {
        .block {
            margin-bottom:1em;
            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }


            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    line-height: 1.6;
                    padding-left: 1.5em;
                    margin-bottom:0.25em;
                    border-bottom: 1px dotted lighten($black, 30%);

                    a {
                        display: block;
                        text-decoration: none;
                        padding: 0.5em 0 0.5em 0;
                    }
                    a:hover, a.activesibling { }
                }

                li  {
                    &:before {
                        content: "\f105";
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 300;
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 2.2;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */


    /*TOP BLOCKS INNER PAGES*/
  
    body:not(.Home) #topblocks {

        background-color: darken($white, 10%);

        div.block { 
            max-height: 350px;
		    position: relative;
            overflow:hidden;
            margin-bottom:0;

            > p {margin:0 0;}

            img {
              /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
              width: auto;
              height: auto;
            }
		
		    .caption {
			    @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
			    width: 95%;
			    text-align: center;
                padding: 1em;
		    }
        }

    }
   



	
/******************************************************************
FORM STYLES
******************************************************************/
    /* show/hid fields on gift voucher form*/
    .hide {
        
    }


    .respond-form {
        margin: 0em auto;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

.respond-form, #address {
    /* not nested as not all forms contain a table */
    table {
        width: 100%
    }

    form {
        margin: 0 0;

        li {
            list-style-type: none;
            clear: both;
            margin-bottom: 0.7335em;

            label,
            small {
                display: none;
            }
        }

        fieldset {
            border: 0;
            margin: 0 0;
            padding: 0 0 0 0;
        }

        input[type=text],
        input[type=email],
        input[type=date],
        input[type=url],
        input[type=tel],
        textarea,
        select {
            padding: 1em;
            background-color: lighten($color-secondary,20%);
            margin: 0 0 1em 0;
            display: table-cell;
            width: 100%;
            max-width: 100%;
            border: none;
            @include border-radius($small-border-radius);
            border-bottom: 1px solid $white;
            

            &:focus {
                /*background-color: #fff;*/
            }
            /* form validation - comment out as editor doesn't support some of the html5 validation techniques */
            /* &:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}*/
        }

        input[type=checkbox] {
            margin-right: 5px;
        }

        input[name="180_LeaseEnquiry"] {
            margin-bottom: 1em;
        }


        .required {
            background-position: right top;
            background-repeat: no-repeat;
        }

        textarea {
            height: 80px;
        }

        img {
            float: left;

            &.fullwidth {
                max-width: 100%;
            }
        }
    }
}

    #address form {
        margin: 0 auto;
        input[type=email] {width:auto;padding: 0.5em;}
        input[type="submit"] {min-height: 36px;}
    }


/*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }


    

/*********************
FOOTER STYLES
*********************/
    #bottomBlocks,  #cta{ background: lighten($black, 5%);}

    #bottomBlocks {position:relative; }

    body.Template-2 {
        #bottomBlocks {
            .respond-form {padding-top:1em;}
        }
    }

    #footer {
        background: $black;

         p.socials a{
            display: inline-block;
            padding: 0.25em;
            margin-right:0.25em;
        }

         nav a {
             margin: 0 0.25em; display:inline-block; padding: 0.5em 0; line-height:2;
             &:last-of-type {margin-right:0;}
         }

        > div {

            nav {
                ul {
                    li {
                        border-bottom: 1px dotted $color-quaternary;

                        a {
                            display: block;
                            line-height: 2;
                        }
                    }
                }
            }  
        }
        #copyright {
           
            a {display:inline-block; padding: 0.5em 0; line-height:2; }
            
       }
    }

    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: $white;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }

#leftcol li.slick-slide:before,
#leftcol ul.responsive .slick-dots li:before  {display:none;}

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */
/*.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-perspective: 1000px;
}*/
/* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;
        position: relative;
        display:flex;
        z-index: map-get($zindex, sendBackwards);

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                	padding:0;
                    z-index: map-get($zindex, sendToBack);

                    .slideshow_caption {
                    }
                }
            }
        }

        button:not(.primary) {
            padding: 0.2em;
            z-index: map-get($zindex, bringToFront);

            /*&:before {
                content: "\f104";
                font-family:"Font Awesome 6 Pro";
                font-weight: 300;
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }*/
        }

        /*.slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }*/
    }

    .slick-prev, .slick-next {
       /* top: 48%; width:50px; height:50px; background-color:red;outline:none;border:none;

        background:transparent;
        position:absolute;
        top:0;
        width:50%;
        height:80%;
        cursor: url(../images/arrow-left.png),default;*/
       display:none;
       visibility:hidden;
        
    }

    .slick-prev {
        left: 0em;
    }

    .slick-next {
        right: 0em;
        cursor: url(../images/arrow-right.png),default;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
    }

    ul.slick-dots {
        text-align:left;
        padding-left:20vw!important;
        li {
            width:30px; height:40px;
            button {
                width:30px; height:40px;
                &:before {
                    color:$color-primary;
                    font-size:40px;
                    opacity:0.75;
                }

            }
        }
        li.slick-active {
            button {
                &:before {
                    color:$color-primary;
                    opacity:1;
                }

            }
        }
    }

    

    /*Full width slider with captions, dots and arrows */
   
    ul.responsive {
        /*padding-bottom: 3em;*/
        overflow: visible;

        .slick-list {

            .slick-track {

                .slick-slide {

                   
                   img {@include transition(all 0.3s ease-in-out); margin-bottom:0%;}
                    
                
                    .slideshow_caption {
                        /*text-align: center;*/
				        width: 100%;
                        right:unset; 
                        bottom:0;
                        position: relative; 
                        @include transition(all 0.3s ease-in-out);

                        .button {vertical-align:middle; margin-bottom:0.25em;}
                    }

                    .slideshow_caption {
                        display: none; /* Initially hide all captions */
                        opacity: 0;
                    }

                    .slideshow_caption.active {
                        display: block; /* Show the active caption */
                        background-color: lighten($color-tertiary, 20%);
                        box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
                        border-radius: 0rem 0rem 0.5rem 0.5rem;
                        opacity: 1;
                        padding: 1rem;
                    }


                }

            }
        }

      
        .slick-prev {left: 0 }
        .slick-next {right: 0}
        .slick-dots {bottom:-10%; }
        /*li:before {display:none;}*/
    }

    /*Full width slider with captions */
    ul.responsive3 {
        margin-bottom: 0;

        .slick-list {

            .slick-track {

                .slick-slide {
					height: 420px;
                    height: 70vh;
					
					&:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.25); }
                    picture {position: relative;}
                    img {
                        /*height: 420px;*/
                        max-width: none;
                        width: auto;                       
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%,-50%);
                        transition: transform 6s;
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;

                    }
					
					p:last-of-type{margin-bottom: 0.75em;}
					
					a.button:first-of-type {animation-delay: 1s;}
					a.button {animation-delay: 1.5s;}
										
                }

                .slick-slide.slick-active  {

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(1.05);
                        -webkit-transform: translate(-50%, -50%) scale(1.05);
                        -ms-transform: translate(-50%, -50%) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
					
					
										
                }


				.slideshow_caption {opacity:0; visibility:hidden;display:none; @include transition(all 0.5s ease-in-out); position: relative;}
				.slideshow_caption.activate {background: rgba(255, 255, 255,0); opacity:1; visibility: visible;display:block; text-align: center; @include transition(all 0.5s ease-in-out);}
                .slideshow_caption p.h2 {display:none;}
				
            }
        }
			
    }

// HOME TEMPLATE 2
body.Template-2 {
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 100vh;
        .slideshow_caption p.h2 {display:block;}
        .slideshow_caption p.h4 {display:none;}
    }
}

// HOME TEMPLATE 3
body.Template-3 {
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 100vh;
    }
}

// HOME TEMPLATE 4
body.Template-4 {
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 75vh;
    }
}

    /************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
/*********************
CUSTOMISE colorbox
*********************/
    #cboxContent {padding: 0 40px}
    
    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxNext, #cboxPrevious{
        top: 50%;
    }

    #cboxPrevious{
        left:0;
    }
    #cboxNext{
        right:0;
    }

    #cboxTitle {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 3em;
        right: 0.5em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background:darken($color-tertiary, 5%);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);
        border-radius:50%;


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }
/* used for pagination on the news listing page*/
div.new-items-pagination {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    background: lighten(#000000, 85%);
    margin: 1em auto;
    padding: 0;

    .news-item-label, .news-items-next {
        padding: 0.6em;
    }

    .news-items-next {
        text-align: right;
    }
}

// BOOK FORM Slide-out
#bookForm{
    float:right;
    overflow: hidden;
    position: fixed;
    top:0;
    right:0;
    background:$white;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.2);
    height:100vh;
    z-index: map-get($zindex, bookForm);

    #bookForm-inner {
        width: 90vw;
        padding: 2em 1.25em;

        a.searchClose {
            display:inline-block;
            text-align:center;
            span {display: block;}
            &:hover {cursor:pointer;}
        }
    }
}


